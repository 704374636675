import EnrolWelcomeBar from 'components/EnrolWelcome/EnrolWelcomeBar'
import FormLogin from 'components/LoginAndSignUpForm/FormLogin'
import FormRegister from 'components/LoginAndSignUpForm/FormRegister'
import { useState } from 'react'
type LoginAndSignUpFormProps = {
  defaultTab?: string
  redirectUrl?: string
}

const LoginAndSignUpForm = ({
  defaultTab = 'sign-in',
  redirectUrl,
}: LoginAndSignUpFormProps) => {
  const [selected, setSelected] = useState(defaultTab)
  const DisplayForm = selected === 'sign-in' ? FormLogin : FormRegister
  return (
    <div className={'bg-white w-full rounded-xl border-[1px] border-mischka'}>
      <div className={'flex w-full content-center px-6 py-2'}>
        <button
          onClick={() => setSelected('sign-in')}
          className={`flex w-1/2 items-center flex-col uppercase px-5 py-3 border-b-2 font-sans text-sm ${
            selected === 'sign-in' ? 'border-black' : 'border-transparent'
          }`}
        >
          sign in
        </button>
        <button
          onClick={() => setSelected('sign-up')}
          className={`flex w-1/2 items-center flex-col uppercase px-5 py-3 border-b-2 font-sans text-sm ${
            selected === 'sign-up' ? 'border-black' : 'border-transparent'
          }`}
        >
          sign up
        </button>
      </div>
      <div className={'px-6 pb-12'}>
        <DisplayForm redirectUrl={redirectUrl} />
      </div>
    </div>
  )
}

export default LoginAndSignUpForm

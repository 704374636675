import Image from 'next/image'
import IconApple from 'public/apple-icon.png'
import React from 'react'

type AppleButtonProps = {
  isSubmitting: boolean
  onClickLoginApple: any
}

const AppleButton = ({
  isSubmitting = false,
  onClickLoginApple,
}: AppleButtonProps) => {
  return process.env.ENABLE_APPLE_APP ? (
    <button
      type='button'
      disabled={isSubmitting}
      className='disabled:opacity-50 w-full inline-flex justify-center gap-x-3 px-3 py-3 border-2 border-cadet-blue rounded-full text-sm text-cape-cod font-medium hover:bg-gray-50 items-center font-sans'
      onClick={onClickLoginApple}
    >
      <Image
        src={IconApple}
        width={17}
        height={20}
        alt={'Sign in with Apple'}
      />
      Continue with Apple
    </button>
  ) : (
    <></>
  )
}

export default AppleButton

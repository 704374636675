import Alert from 'components/Alert/Alert'
import FormField from 'components/FormField'
import AppleButton from 'components/LoginAndSignUpForm/AppleButton'
import FacebookButton from 'components/LoginAndSignUpForm/FacebookButton'
import GoogleButton from 'components/LoginAndSignUpForm/GoogleButton'
import { signIn } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type FormValues = {
  email: string
  password: string
}

type FormLoginProps = {
  onSuccess?: (() => void) | null
  redirectUrl?: string
}

const FormLogin = ({ onSuccess = null, redirectUrl = '/' }: FormLoginProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isSubmitting, setSubmitting] = useState(false)
  const [loginError, setLoginError] = useState<string | null>(null)

  const path = router.asPath.replace(/\?.*/, '')
  const activated = (router.query.activated || '') as string
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setFocus,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { email: activated, password: '' },
  })

  const onClickLoginGoogle = () => {
    // SET local element to wants to login
    return signIn('google', {
      callbackUrl: redirectUrl,
    })
  }

  const onClickLoginApple = () => {
    // SET local element to wants to login
    return signIn('apple', {
      callbackUrl: redirectUrl,
    })
  }

  const onClickLoginFacebook = () => {
    return signIn('facebook', {
      callbackUrl: redirectUrl,
    })
  }

  const handleLogin = async (values: FormValues) => {
    await setSubmitting(true)
    await setLoginError(null)
    // use shallow to get rid of querystrings without reload
    router.replace(path, undefined, { shallow: true })
    return await signIn('credentials', {
      ...values,
      action: 'login',
      redirect: false,
    })
      .then((result) => {
        if (result?.error) {
          setLoginError(t('Email, Student ID, or password incorrect'))
          setSubmitting(false)
        } else if (onSuccess) {
          onSuccess()
        } else {
          router.push(
            redirectUrl ||
              (router.query.callbackUrl ? `${router.query.callbackUrl}` : '/'),
          )
        }
      })
      .catch(() => {
        setLoginError(t('Email, Student ID, or password incorrect'))
        setSubmitting(false)
      })
  }

  useEffect(() => {
    setFocus('email')
  }, [setFocus])
  return (
    <>
      {loginError && <Alert type='error'>{loginError}</Alert>}
      <div className='mt-6 space-y-2'>
        {process.env.ENABLE_FACEBOOK_APP && (
          <FacebookButton
            isSubmitting={isSubmitting}
            onClickLoginFacebook={onClickLoginFacebook}
          />
        )}
        {process.env.ENABLE_GOOGLE_APP && (
          <GoogleButton
            isSubmitting={isSubmitting}
            onClickLoginGoogle={onClickLoginGoogle}
          />
        )}
        {process.env.ENABLE_APPLE_APP && (
          <AppleButton
            isSubmitting={isSubmitting}
            onClickLoginApple={onClickLoginApple}
          />
        )}
      </div>
      <FormDivider label='OR' />
      <form onSubmit={handleSubmit(handleLogin)}>
        <label
          className={'font-medium font-sans text-secondary-ui-700 text-sm'}
          htmlFor='loginEmail'
        >
          {t('Email or Student ID')}
        </label>
        <FormField
          id='loginEmail'
          type='text'
          className={'h-5'}
          error={errors.email}
          register={register('email', {
            required: true,
          })}
        />
        <div className={'py-0'}>
          <label
            className={'font-medium font-sans text-secondary-ui-700 text-sm'}
            htmlFor='loginPassword'
          >
            {t('Password')}
          </label>
          <FormField
            id='loginPassword'
            type='password'
            className={'h-5'}
            error={errors.password}
            register={register('password', {
              required: true,
            })}
          />
        </div>
        <div className='underline mt-0 mb-2 text-right text-sm'>
          <Link href='/reset-password' passHref>
            <a>{t('Forgot your password?')}</a>
          </Link>
        </div>
        <div className='text-center py-2'>
          <button
            type='submit'
            disabled={!isValid || isSubmitting}
            className='w-full rounded-full bg-purple-heart text-white p-3 disabled:bg-secondary-ui-300 hover:bg-gray-600'
          >
            {t('Sign in')}
          </button>
        </div>
      </form>
    </>
  )
}

/**
 *
 * @param props
 * @returns
 */
export const FormDivider = (props: { label: string }) => {
  return (
    <div className='relative mt-10 mb-6'>
      <div className='absolute inset-0 flex items-center'>
        <div className='w-full border-t border-secondary-ui-900' />
      </div>
      <div className='relative flex justify-center text-sm'>
        <span className='px-2 bg-white text-cadet-blue'>{props.label}</span>
      </div>
    </div>
  )
}

export default FormLogin

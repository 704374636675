import { User } from 'types/user'

export interface UserTokenResult {
  user: User
  token: string
  expires: string
  refreshExpires: string
}

export interface SessionMutationResult {
  session: UserTokenResult
}

export enum UserRegisterResult {
  SUCCESS = 'SUCCESS',
  FAIL_USER_EXISTS = 'FAIL_USER_EXISTS',
  FAIL_VALIDATION = 'FAIL_VALIDATION',
}

export interface RegisterUserResponse {
  status: UserRegisterResult
  message: string
  session: UserTokenResult
  errors: object
}

export interface RegisterUserMutationResult {
  registration: RegisterUserResponse
}

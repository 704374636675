import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React, { useState } from 'react'

type AlertProps = {
  type?: 'error' | 'success'
  children: React.ReactNode
  className?: string
}

const Alert = ({ type, children, className = '' }: AlertProps) => {
  const [showing, setShowing] = useState(true)
  return showing ? (
    <div
      className={classNames(
        'border rounded-md text-base leading-tight px-3 py-2 mb-6 flex space-x-2',
        type === 'error'
          ? 'border-red-500 bg-red-100 text-red-500'
          : type === 'success'
            ? 'border-tech-green bg-tech-green text-black'
            : 'bg-accent-2-50 text-accent-2-700',
        className,
      )}
    >
      <div className='flex-1'>{children}</div>
      <div className='flex-shrink-0'>
        <button
          type='button'
          onClick={() => setShowing(false)}
          className='block'
        >
          <XIcon className='w-5 h-5 text-gray-400' />
        </button>
      </div>
    </div>
  ) : null
}

export default Alert

import Image from 'next/image'
import IconFacebook from 'public/facebook-icon.png'
import React from 'react'

type FacebookButtonPros = {
  isSubmitting: boolean
  onClickLoginFacebook: any
}

const FacebookButton = ({
  isSubmitting = false,
  onClickLoginFacebook,
}: FacebookButtonPros) => {
  return (
    <button
      type='button'
      disabled={isSubmitting}
      className='disabled:opacity-50 w-full inline-flex justify-center gap-x-3 px-3 py-3 border-2 border-cadet-blue rounded-full text-sm text-cape-cod font-medium hover:bg-gray-50 items-center font-sans'
      onClick={onClickLoginFacebook}
    >
      <Image src={IconFacebook} width={20} height={20} />
      Continue with Facebook
    </button>
  )
}

export default FacebookButton

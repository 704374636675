import Alert from 'components/Alert/Alert'
import FormField from 'components/FormField'
import AppleButton from 'components/LoginAndSignUpForm/AppleButton'
import FacebookButton from 'components/LoginAndSignUpForm/FacebookButton'
import { FormDivider } from 'components/LoginAndSignUpForm/FormLogin'
import GoogleButton from 'components/LoginAndSignUpForm/GoogleButton'
import PasswordStrengthIndicator from 'components/PasswordStrengthIndicator/PasswordStrengthIndicator'
import { validatePassword } from 'lib/validation'
import { signIn } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { UserRegisterResult } from 'types/session'
import { ZXCVBNResult } from 'zxcvbn'
import { useLocation } from '../../context/Location/LocationProvider'
import { useSettings } from '../../context/SettingsProvider/SettingsContext'

type RegisterFormValues = {
  first_name: string
  last_name: string
  preferred_name: string
  phone: string
  email: string
  country: string
  password: string
  password_confirmation: string
  confirmBox: boolean
}

type FormRegisterProps = {
  onSuccess?: (() => void) | null
  redirectUrl?: string
}

const FormRegister = ({
  onSuccess = null,
  redirectUrl = '/',
}: FormRegisterProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isSubmitting, setSubmitting] = useState(false)
  const [registerError, setRegisterError] = useState<string | null>(null)
  const [passwordStrengthResult, setPasswordStrengthResult] =
    useState<ZXCVBNResult>()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
    setFocus,
  } = useForm<RegisterFormValues>({
    mode: 'onChange',
  })
  const { country } = useLocation()
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  // Pre-select country
  useEffect(() => {
    if (country) {
      setValue('country', country)
    }
  }, [country])

  const onClickLoginGoogle = () => {
    // SET local element to wants to login
    return signIn('google', {
      callbackUrl: redirectUrl,
    })
  }

  const onClickLoginApple = () => {
    // SET local element to wants to login
    return signIn('apple', {
      callbackUrl: redirectUrl,
    })
  }

  const onClickLoginFacebook = () => {
    return signIn('facebook', {
      callbackUrl: redirectUrl,
    })
  }

  const handleRegister = async (values: RegisterFormValues) => {
    if (process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
      await recaptchaRef.current?.executeAsync()
      try {
        await fetch('/api/handlerecaptcha', {
          method: 'POST',
          body: JSON.stringify({ captcha: recaptchaRef.current?.getValue() }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {
        setTimeout(() => recaptchaRef.current?.reset(), 500)
        setRegisterError('Please try the CAPTCHA one more time.')
        setSubmitting(false)
        return
      }
    }

    await setSubmitting(true)
    await setRegisterError(null)
    return await signIn('credentials', {
      ...values,
      action: 'register',
      redirect: false,
    })
      .then((data) => {
        // Handle login error
        if (data?.error) {
          setTimeout(() => recaptchaRef.current?.reset(), 500)
          // Handle registration errors
          switch (data.error) {
            case UserRegisterResult.FAIL_USER_EXISTS:
              setRegisterError(t('This user already exists, please login.'))
              setSubmitting(false)
              break
            case UserRegisterResult.FAIL_VALIDATION:
              setRegisterError(
                t('There was an error registering your account.'),
              )
              setSubmitting(false)
              break
            default:
              setRegisterError(data.error)
              setSubmitting(false)
              break
          }
        } else if (onSuccess) {
          onSuccess()
        } else {
          router.push(
            redirectUrl ||
              (router.query.callbackUrl ? `${router.query.callbackUrl}` : '/'),
          )
        }
      })
      .catch((e) => {
        if (e instanceof Error) {
          setRegisterError(e.message)
        } else {
          setRegisterError(t('There was an error registering your account.'))
        }
        setSubmitting(false)
      })
  }

  useEffect(() => {
    setFocus('first_name')
  }, [setFocus])

  const setting = useSettings()
  return (
    <>
      {!!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
        <Script src='https://www.google.com/recaptcha/api.js' />
      )}
      {registerError && <Alert type='error'>{registerError}</Alert>}
      <div className='mt-6 space-y-2'>
        {process.env.ENABLE_FACEBOOK_APP && (
          <FacebookButton
            isSubmitting={isSubmitting}
            onClickLoginFacebook={onClickLoginFacebook}
          />
        )}
        {process.env.ENABLE_GOOGLE_APP && (
          <GoogleButton
            isSubmitting={isSubmitting}
            onClickLoginGoogle={onClickLoginGoogle}
          />
        )}
        {process.env.ENABLE_APPLE_APP && (
          <AppleButton
            isSubmitting={isSubmitting}
            onClickLoginApple={onClickLoginApple}
          />
        )}
      </div>
      <FormDivider label='OR' />
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className={'w-full md:flex flex-row gap-2'}>
          <div className={'w-full md:w-1/2'}>
            <label
              className={'font-medium font-sans text-secondary-ui-700 text-sm'}
              htmlFor='signupFirstName'
            >
              {t('First name')}
            </label>
            <FormField
              id='signupFirstName'
              type='text'
              error={errors.first_name}
              register={register('first_name', { required: true })}
            />
          </div>
          <div className={'w-full md:w-1/2'}>
            <label
              className={'font-medium font-sans text-secondary-ui-700 text-sm'}
              htmlFor='signupLastName'
            >
              {t('Last name')}
            </label>
            <FormField
              id='signupLastName'
              type='text'
              error={errors.last_name}
              register={register('last_name', { required: true })}
            />
          </div>
        </div>
        <div className={'w-full'}>
          <label
            className={'font-medium font-sans text-secondary-ui-700 text-sm'}
            htmlFor='signupEmail'
          >
            {t('Email address')}
          </label>
          <FormField
            id='signupEmail'
            type='email'
            error={errors.email}
            register={register('email', {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
        </div>
        <div className={'w-full'}>
          <label
            className={'font-medium font-sans text-secondary-ui-700 text-sm'}
            htmlFor='signupPassword'
          >
            {t('Password')}
          </label>
          <div className='relative'>
            <FormField
              id='signupPassword'
              type='password'
              error={errors.password}
              register={register('password', {
                required: true,
                validate: validatePassword(setPasswordStrengthResult),
              })}
            />
            {watch('password') && passwordStrengthResult && (
              <PasswordStrengthIndicator result={passwordStrengthResult} />
            )}
          </div>
        </div>
        <div className={'w-full'}>
          <label
            className={'font-medium font-sans text-secondary-ui-700 text-sm'}
            htmlFor='signupPasswordConfirmation'
          >
            {t('Confirm password')}
          </label>
          <div className='relative'>
            <FormField
              id='signupPasswordConfirmation'
              type='password'
              error={errors.password_confirmation}
              register={register('password_confirmation', {
                required: true,
                validate: (value) =>
                  value === watch('password') ||
                  `${t('Passwords do not match')}`,
              })}
            />
          </div>
        </div>
        {!!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
          <div className='text-center w-full'>
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
              ref={recaptchaRef}
              size='invisible'
            />
          </div>
        )}
        <div className='text-center'>
          <button
            type='submit'
            disabled={!isValid || isSubmitting}
            className='w-full rounded-full bg-purple-heart py-3 text-white disabled:bg-secondary-ui-300 hover:bg-gray-600'
          >
            {t('Sign up')}
          </button>
        </div>
        <div className='text-center pt-6 text-xs text-secondary-ui-700 font-sans px-6 md:px-20 leading-5'>
          By clicking &ldquo;Sign up&ldquo;, you agree to our{' '}
          <a
            href={setting(
              'app.terms-link',
              'https://namehere.com/terms-conditions',
            )}
            className='underline'
            target='_blank'
            rel='noreferrer noopener'
          >
            Terms of Use
          </a>{' '}
          and our{' '}
          <a
            href={setting(
              'app.privacy-policy',
              'https://namehere.com/privacy-policy',
            )}
            className='underline'
            target='_blank'
            rel='noreferrer noopener'
          >
            Privacy Policy
          </a>
          .
        </div>
      </form>
    </>
  )
}

export default FormRegister

import classNames from 'classnames'
import { ReactChild } from 'react'

type InputType = 'text' | 'select' | 'textarea' | 'password' | 'email'
type InputTag = 'input' | 'textarea' | 'select'

type FormFieldProps = {
  icon?: ReactChild
  type?: InputType
  placeholder?: string
  error?: any
  register?: any
  onChange?: () => void
  defaultValue?: string
  className?: string
  children?: React.ReactNode
  id?: string
  [key: string]: any
}

const FormField = ({
  icon,
  type = 'text',
  placeholder,
  error,
  register,
  className,
  children,
  ...rest
}: FormFieldProps) => {
  // Pick correct tag type
  let Tag: InputTag = 'input'
  switch (type) {
    case 'textarea':
    case 'select':
      Tag = type
      break
  }

  return (
    <div className='mb-6'>
      <div className='relative'>
        {icon && (
          <div className='w-5 h-5 text-purple-heart absolute top-1/2 transform -translate-y-1/2 left-3'>
            {icon}
          </div>
        )}
        <Tag
          className={classNames(
            'w-full rounded-md placeholder-gray-400 p-3 h-12',
            error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300',
            icon && 'pl-10',
            type === 'select' ? 'pt-3' : 'pt-4',
            className,
          )}
          type={type}
          placeholder={placeholder}
          {...register}
          {...rest}
        >
          {children || null}
        </Tag>
      </div>
      {error?.type === 'required' ? (
        <div className='text-sm mt-3 mb-6 text-red-500'>
          This field is required
        </div>
      ) : (
        error?.message && (
          <div className='text-sm mt-3 mb-6 text-red-500'>{error.message}</div>
        )
      )}
    </div>
  )
}

export default FormField

import classNames from 'classnames'
import { ZXCVBNResult } from 'zxcvbn'

type PasswordStrengthIndicatorProps = {
  result?: ZXCVBNResult
}

const PasswordStrengthIndicator = ({
  result,
}: PasswordStrengthIndicatorProps) => {
  return (
    <div className='h-1 bg-gray-100 absolute bottom-0 right-px left-px rounded-b-md overflow-hidden top-12 -translate-y-full -mt-px'>
      <div
        className={classNames(
          'h-full transition origin-left',
          result ? `pass-score-${result?.score}` : '',
        )}
      />
    </div>
  )
}

export default PasswordStrengthIndicator

import Image from 'next/image'
import IconGoogle from 'public/google-icon.png'
import React from 'react'

type GoogleButtonProps = {
  isSubmitting: boolean
  onClickLoginGoogle: any
}

const GoogleButton = ({
  isSubmitting = false,
  onClickLoginGoogle,
}: GoogleButtonProps) => {
  return (
    <button
      type='button'
      disabled={isSubmitting}
      className='disabled:opacity-50 w-full inline-flex justify-center gap-x-3 px-3 py-3 border-2 border-cadet-blue rounded-full text-sm text-cape-cod font-medium hover:bg-gray-50 hover:text-white items-center font-sans'
      onClick={onClickLoginGoogle}
    >
      <Image className='rounded' src={IconGoogle} width={20} height={20} />
      Continue with Google
    </button>
  )
}

export default GoogleButton

import React from 'react'
import zxcvbn, { ZXCVBNResult } from 'zxcvbn'

const MIN_PASSWORD_STRENGTH = 3

export const validatePassword =
  (
    setPasswordStrengthResult: React.Dispatch<
      React.SetStateAction<ZXCVBNResult | undefined>
    >,
  ) =>
  (value: string): string | boolean => {
    const strength = zxcvbn(value)
    setPasswordStrengthResult(strength)

    // Check if valid
    if (strength.score >= MIN_PASSWORD_STRENGTH) {
      return true
    }

    // build suggestion on how to improve
    const warning = strength?.feedback?.warning || 'Your password is too weak'
    const suggestions = strength?.feedback?.suggestions?.[0] || null
    return `${warning} - ${suggestions}`
  }
